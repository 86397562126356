import { Modal, Paper, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
  },
  paper: {
    //center Container for modal contents
    width: "75%",
    height: "75%",
    position: "absolute",
    top: (window.innerHeight / 100) * 12.5,
    left: (window.innerWidth / 100) * 12.5,
  },
}));

const ModalWrapper = (props) => {
  const { open, handleClose } = props;
  const classes = useStyles();
  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Paper className={classes.paper}>{props.children}</Paper>
      </Modal>
    </>
  );
};

export default ModalWrapper;
