import AppBar from "@material-ui/core/AppBar";
import { makeStyles, Grid, Typography, Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
  },
  leftGrid: {},
  middleGrid: {},
  rightGrid: {
    paddingRight: "20px",
  },
}));

const onClick = (button, handleOpenModal) => {
  switch (button) {
    case "upload":
      console.log("Upload CSV");
      handleOpenModal("upload");
      break;

    case "change-iday":
      console.log("Change Date");
      handleOpenModal("change-iday");
      break;

    case "wipe-account":
      console.log("Wipe Account");
      handleOpenModal("wipe-account");
      break;

    default:
      break;
  }
};

const NavBar = ({ handleOpenModal, handleCloseModal }) => {
  const classes = useStyles();

  return (
    <AppBar
      position="static"
      color="inherit"
      display="flex"
      className={classes.root}
    >
      <Grid
        container
        alignItems="center"
        direction="row"
        wrap="nowrap"
        justify="space-between"
      >
        <Grid
          container
          item
          xs={4}
          justify="flex-start"
          wrap="nowrap"
          direction="row"
          alignItems="center"
          className={classes.leftGrid}
        >
          <img
            style={{ border: "none" }}
            alt="ca-cart-logo"
            width="60"
            src="https://static.commerce-architects.com/emailsignature/ca-cart-logo.png"
          />
          <Typography variant="h6" color="primary">
            Commerce <span style={{ color: "#7a7a7a" }}>Architects</span>
          </Typography>
        </Grid>
        <Grid item xs={4} className={classes.middleGrid}>
          <Typography variant="h4" noWrap={true} align="center">
            CA Immersion Day Tools
          </Typography>
        </Grid>
        <Grid
          xs={4}
          item
          container
          direction="row"
          justify="space-between"
          className={classes.rightGrid}
        >
          <Button
            onClick={() => {
              onClick("upload", handleOpenModal, handleCloseModal);
            }}
            variant="contained"
          >
            Upload CSV
          </Button>
          <Button
            onClick={() => {
              onClick("change-iday", handleOpenModal, handleCloseModal);
            }}
            variant="contained"
          >
            Change Date
          </Button>
          <Button
            onClick={() => {
              onClick("wipe-account", handleOpenModal, handleCloseModal);
            }}
            variant="contained"
          >
            Wipe Account
          </Button>
        </Grid>
      </Grid>
    </AppBar>
  );
};

export default NavBar;
