/* 
  Wipe Account Component that can be used inside the ModalWrapper Component.  
  Component grabs accounts from DynamoDB table using the '/user' API Gateway endpoint.  Accounts are then displayed in drop down 
  menu for the user to select.  Once an account is selected, user can click 'wipe account' button to call the nukeResources API Gateway endpoint to wipe
  all resources from the currently selected account.
 */

import {useState, useEffect} from "react";
import axios from "axios";
import {
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(10),
    minWidth: "350px",
  },
  flexBox: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  modalTitle: {
    padding: "20px 30px",
    fontSize: "4vh",
    color: "white",
    minWidth: "300px",
    backgroundColor: "#618833",
  },
  message: {
    marginTop: "40px",
    padding: "20px",
    width: "90%",
    textAlign: "center",
    color: "white",
    backgroundColor: "#689630",
    borderRadius: "20px",
    fontFamily: "Roboto",
  },
}));

const WipeAccount = (props) => {
  const classes = useStyles();

  const { handleClose } = props;

  const [account, setAccount] = useState(undefined);
  const [accounts, setAccounts] = useState([]);
  const [messageVisibility, setMessageVisibility] = useState("hidden");
  const [messageText, setMessageText] = useState("");

  //Grab accounts with axios
  useEffect(() => {
    axios
      .get("https://ojmc63dai6.execute-api.us-west-2.amazonaws.com/dev/users")
      .then((res) => {
        setAccounts(res.data);
      })
      .catch((err) => {
        console.error("Axios call to get accounts failed -- " + err);
      });
  }, []);

  const handleChange = (event) => {
    setAccount(event.target.value);
  };

  const handleAccountWipe = () => {
    let confirmation = window.confirm(
      `Do you confirm account wipe of ${account.accountAlias}?`
    );
    if (confirmation) {
      const body = {
        accountId: account.accountId,
        accountAlias: account.accountAlias.toLowerCase(),
      };

      axios
        .post(
          "https://ojmc63dai6.execute-api.us-west-2.amazonaws.com/dev/nukeResources",
          body
        )
        .then(() => {
          setMessageText(`${account.accountAlias} is successfully being wiped.`);
          setMessageVisibility("visible");
        })
        .catch((err) => {
          const errorMessage = `Call to wipe ${account.accountAlias} failed unexpectedly.`;
          console.error(errorMessage + " -- " + err);
          setMessageText(errorMessage);
          setMessageVisibility("visible");
        });
    }
  };

  return (
    <>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      />
      <Typography className={classes.modalTitle} variant="h4">
        Wipe Account
      </Typography>
      <Box className={classes.flexBox}>
        <div
          className={classes.message}
          style={{ visibility: messageVisibility }}
        >
          {messageText}
        </div>
        <FormControl variant="filled" className={classes.formControl}>
          <InputLabel id="dropdown-label">Choose an account to wipe</InputLabel>
          <Select
            labelId="dropdown-select-label"
            id="dropdown-select"
            onChange={handleChange}
            autowidth="true"
            defaultValue=""
          >
            {accounts.map((option) => (
              <MenuItem value={option} key={option.accountAlias}>
                {option.accountAlias}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          disabled={account ? false : true}
          onClick={handleAccountWipe}
        >
          Wipe Account
        </Button>
        <Button
          style={{ marginTop: "20px" }}
          onClick={() => {
            handleClose();
          }}
          variant="contained"
          size="small"
        >
          Close
        </Button>
      </Box>
    </>
  );
};

export default WipeAccount;
