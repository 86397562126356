import { createMuiTheme } from "@material-ui/core/styles";

const Theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0f8e4f", //CA Green
    },
    secondary: {
      light: "#ffffff", //White
      main: "#7a7a7a", //CA Grey
    },
  },
  overrides: {
    MuiButton: {
      colorInherit: {
        color: "black",
        backgroundColor: "white",
      },
    },
  },
});

export default Theme;
