import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ContextProvider } from "./Contexts/Context";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import Amplify from "aws-amplify";
import Theme from "./Theme";
import { ThemeProvider } from "@material-ui/core";
import AWS_Config from "./Config/AWS_Config.js";

Amplify.configure(AWS_Config);

const history = createBrowserHistory();

const federated = {
  google_client_id:
    "867340191099-0unr7r2611p0qlv8l137f80lf7730rkm.apps.googleusercontent.com",
};

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <ThemeProvider theme={Theme}>
        <ContextProvider>
          <App federated={federated} />
        </ContextProvider>
      </ThemeProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
