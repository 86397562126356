import React, { createContext, useState } from "react";
import PropTypes from "prop-types";

//To access Context from components:
//      import React, { useContext} from 'react';
//      import Context from './Context';

//...
//      const {queues, setQueues} = useContext(Context);
//...
//      queues
//      setQueues(...)

const Context = createContext();

const ContextProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIday, setCurrentIday] = useState("");
  const [users, setUsers] = useState([]);
  //accounts used to set 'users' state
  const [accounts, setAccounts] = useState([]);

  const context = {
    isLoggedIn,
    setIsLoggedIn,
    isModalOpen,
    setIsModalOpen,
    users,
    setUsers,
    accounts,
    setAccounts,
    currentIday,
    setCurrentIday
  };

  return <Context.Provider value={context}>{children}</Context.Provider>;
};

ContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  initialState: PropTypes.any,
};

export { ContextProvider, Context };
