import { makeStyles, Button, Typography } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { useState } from "react";
import Papa from "papaparse";
import axios from "axios";

const useStyles = makeStyles(() => ({
  outerContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  uploadContainer: {
    display: "flex",
    marginBottom: "30vh",
  },
  buttonContainer: {
    alignSelf: "flex-end",
    marginRight: "3vw",
    display: "flex",
  },
  modalTitle: {
    padding: "20px 30px",
    fontSize: "4vh",
    color: "white",
    minWidth: "300px",
    backgroundColor: "#618833",
  },
  button: {
    padding: "10px 60px",
  },
  csvText: {
    fontFamily: "Roboto",
    color: "#618833",
    fontWeight: "400",
    fontSize: "18px",
  },
  csvButton: {
    marginLeft: "30px",
    height: "40px",
    alignSelf: "center",
    fontSize: "12px"
  },
  csvInnerText: {
      color: "black",
      marginLeft: "10px"
  }
}));

const usersTableUrl = "https://ojmc63dai6.execute-api.us-west-2.amazonaws.com/dev/users";
const setupUsersUrl = "https://ojmc63dai6.execute-api.us-west-2.amazonaws.com/dev/setupUser";
const datesUrl = "https://ojmc63dai6.execute-api.us-west-2.amazonaws.com/dev/dates";
const moveAccountsUrl = "https://ojmc63dai6.execute-api.us-west-2.amazonaws.com/dev/moveAccounts"

const setupUsers = async (newUsers) => {
  const holdingOrganizationOU = "ou-9slr-bwgx61rk";
  const accounts = [];
  const { data } = await axios.get(usersTableUrl);
  const nextImmersionDay = await calculateUpcomingIday();
  const { Name, Id, Date } = nextImmersionDay;

  for (let i = 0; i < newUsers.length; i++) {
    accounts.push({
      accountId: data[i].accountId,
      userId: data[i].userId,
      name: newUsers[i][0],
      email: newUsers[i][1],
      password: newUsers[i][2],
      OU: Id
    });
  }
  console.log(`Accounts below to be added to the ${Name} Immersion Day Event on ${Date}`);
  console.log(accounts);

  const setupUsersResponse = await axios.post(setupUsersUrl, {
    accounts: accounts.map(account => {
      return {
        accountId: account.accountId,
        userId: account.userId
      };
    })
  });
  console.log("result from setupUsers endpoint call below");
  console.log(setupUsersResponse);

  const moveAccountsResponse = await axios.post(moveAccountsUrl, {
    accounts: accounts.map(account => account.accountId),
    sourceOU: holdingOrganizationOU,
    targetOU: Id
  });
  console.log("result from moveAccounts endpoint call below");
  console.log(moveAccountsResponse);

  // Update accounts dynamodb table to with name, email, and immersion day ou id
  const updateAccountsTableResponse = await axios.post(usersTableUrl, accounts);
  const updatedAccounts = updateAccountsTableResponse.data;
  const status = updateAccountsTableResponse.status;
  console.log("result from usersTable endpoint call below");
  console.log(updateAccountsTableResponse);

  if (status === 200) {
    window.alert(`${updatedAccounts.length} accounts successfully updated:\n${updatedAccounts.join('\n')}\nAccounts moved to ${Name} Immersion Day OU for event on ${Date}.`);
  } else {
    window.alert("An error occurred while uploading users. See console for more info.");
  }
};

const calculateUpcomingIday = () => {
  return axios
    .get(datesUrl)
    .then((res) => {
      const iDays = res.data;
      const today = new Date().toISOString().substring(0, 10);

      // sort immersion days by date to find next upcoming iday
      iDays.sort(function (a, b) {
        if (a.Date.length === 0 && b.Date.length > 0) return 1;
        else if (b.Date.length === 0 && a.Date.length > 0) return -1;
        else if (a.Date < today && b.Date >= today) return 1;
        else if (b.Date < today && a.Date >= today) return -1;
        else return a.Date.localeCompare(b.Date);
      });
      // if no dates exist or no upcoming immersion day, set to empty
      if (iDays[0].Date.length === 0 || today > iDays[0].Date) return "";
      else return iDays[0];
    })
    .catch((err) => {
      console.error("Axios GET call to '/dates' endpoint failed -- " + err);
    });
};

const UploadCSV = (props) => {
  const classes = useStyles();
  const { handleClose } = props;
  const [file, setFile] = useState('');

  const handleFileUpload = (event) => {
    if(event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
  }

  const handleCsv = () => {
    Papa.parse(file, {
      complete: function(csv) {
        let confirmation = window.confirm(
          `Do you confirm account creation of these users?`
        );
        if (confirmation) {
          setupUsers(csv.data);
        }
      }
    });
  }

  return (
    <>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      />
      <Typography className={classes.modalTitle} variant="h4">
        Upload CSV
      </Typography>
      <div className={classes.outerContainer}>
        <div className={classes.uploadContainer}>
          <p className={classes.csvText}>
            Current CSV Selected: <span className={classes.csvInnerText}>{file.name? file.name : "NONE"}</span>
          </p>
          <Button
            variant="contained"
            className={classes.csvButton}
            startIcon={<CloudUploadIcon />}
            component="label"
          >
            Browse your files
            <input type="file" accept=".csv" hidden onChange={handleFileUpload}/>
          </Button>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            className={classes.button}
            style={{ marginRight: "20px" }}
            variant="contained"
            disabled={file ? false : true}
            onClick={handleCsv}
          >
            Upload
          </Button>
          <Button className={classes.button} variant="contained" onClick={handleClose}>
            Close
          </Button>
        </div>
      </div>
    </>
  );
};

export default UploadCSV;
