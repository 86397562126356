/*
Change Immersion Day component allows the user to select an immersion day from a drop-down menu.  Once an immersion day is selected, a material-ui date picker will appear
allowing the user to view the current date for the selected immersion day. 

They can use this date picker to also change the date for that Immersion day.  Once a date has been changed, the "Update Date" button will be clickable and they can update 
that immersion day's date in the DynamoDB table.

Component makes axios calls to the aws account's api gateway '/dates' endpoint to grab all immersion day entries and to update an entries date field in the table.
*/

import { useContext, useState, useEffect } from "react";
import { Context } from "../Contexts/Context";
import axios from "axios";
import {
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Typography,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(5),
    minWidth: "350px",
  },
  flexBox: {
    width: "100%",
    height: "75%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "10vh",
  },
  modalTitle: {
    padding: "20px 30px",
    fontSize: "4vh",
    color: "white",
    minWidth: "300px",
    backgroundColor: "#618833",
  },
  buttonContainer: {
    width: "100%",
    marginBottom: "10vh",
    marginRight: "10vw",
    marginTop: "auto",
    display: "flex",
    justifyContent: "flex-end",
  },
  datePicker: {
    display: "flex",
    alignItems: "center",
  },
  scheduleText: {
    fontSize: "18px",
    fontFamily: "Roboto",
    paddingRight: "20px",
  },
}));

const ChangeImmersionDay = (props) => {
  const classes = useStyles();

  const { setCurrentIday } = useContext(Context);
  const { handleClose } = props;

  const [selectedIDay, setSelectedIDay] = useState({ Name: "", Date: "" });
  const [IDayTemp, setIDayTemp] = useState("");
  const [iDaysArray, setIDaysArray] = useState([]);

  const [dateChanged, setDateChanged] = useState(false);
  const [isLoading, setLoading] = useState(true);

  //Grab all the immersion days on component mount
  useEffect(() => {
    axios
      .get(`https://ojmc63dai6.execute-api.us-west-2.amazonaws.com/dev/dates`)
      .then((res) => {
        const sortedIDays = res.data;
        //sort results alphabetically
        sortedIDays.sort(function (a, b) {
          return a.Name.localeCompare(b.Name);
        });
        //Add an empty option at top of list to remove material-ui warning
        sortedIDays.unshift({ Name: "", Date: "", Id: "" });
        setIDaysArray(sortedIDays);
        setSelectedIDay(sortedIDays[0]);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Axios GET call to '/dates' endpoint failed -- " + err);
      });
  }, []);

  const updateDate = (requestBody) => {
    return axios
      .post(
        `https://ojmc63dai6.execute-api.us-west-2.amazonaws.com/dev/dates`,
        requestBody
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.error("Axios call to update IDay date failed -- " + err);
      });
  };

  const calculateUpcomingIday = () => {
    return axios
      .get(`https://ojmc63dai6.execute-api.us-west-2.amazonaws.com/dev/dates`)
      .then((res) => {
        const iDays = res.data;
        const today = new Date().toISOString().substring(0, 10);

        //sort immersion days by date to find next upcoming iday
        iDays.sort(function (a, b) {
          if (a.Date.length === 0 && b.Date.length > 0) return 1;
          else if (b.Date.length === 0 && a.Date.length > 0) return -1;
          else if (a.Date < today && b.Date >= today) return 1;
          else if (b.Date < today && a.Date >= today) return -1;
          else return a.Date.localeCompare(b.Date);
        });
        //If no dates exist or no upcoming immersion day, set to empty
        if (iDays[0].Date.length === 0 || today > iDays[0].Date) return "";
        else return iDays[0];
      })
      .catch((err) => {
        console.error("Axios GET call to '/dates' endpoint failed -- " + err);
      });
  };

  const handleIDayChange = (event) => {
    setSelectedIDay(event.target.value);
    setDateChanged(false);
    setIDayTemp("");
  };

  const handleDateChange = (event) => {
    const newIDay = {
      Name: selectedIDay.Name,
      Date: event.target.value,
      Id: selectedIDay.Id,
    };
    setIDayTemp(newIDay);
    setDateChanged(true);
  };

  const handleChangeConfirm = async () => {
    await updateDate(IDayTemp);
    const upcomingIday = await calculateUpcomingIday();
    setCurrentIday(upcomingIday);

    handleClose();
  };

  if (isLoading) {
    return <div></div>;
  }

  return (
    <>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      />
      <Typography className={classes.modalTitle} variant="h4">
        Change Immersion Day Date
      </Typography>
      <Box className={classes.flexBox}>
        <FormControl variant="filled" className={classes.formControl}>
          <InputLabel id="dropdown-label">Choose an Immersion Day</InputLabel>
          <Select
            value={selectedIDay}
            onChange={handleIDayChange}
            autowidth="true"
          >
            {iDaysArray.map((option) => (
              <MenuItem value={option} key={option.Name}>
                {option.Name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div
          className={classes.datePicker}
          style={{ visibility: selectedIDay.Id ? "visible" : "hidden" }}
        >
          <form noValidate>
            <TextField
              id="dateScheduler"
              type="date"
              value={IDayTemp.Date ? IDayTemp.Date : selectedIDay.Date}
              onChange={handleDateChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </form>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            style={{ marginRight: "20px" }}
            variant="contained"
            disabled={dateChanged ? false : true}
            onClick={handleChangeConfirm}
          >
            Update Date
          </Button>
          <Button
            onClick={() => {
              handleClose();
            }}
            variant="contained"
          >
            Close
          </Button>
        </div>
      </Box>
    </>
  );
};

export default ChangeImmersionDay;
