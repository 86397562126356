/*
  Component displays the next upcoming Iday and its scheduled date.  
  
  On component mount, a call is made to aws Api Gateway '/dates' endpoint to grab all immersion days from the 'IdayDates' table. 
  These are sorted by 'Date' field in order to select the next upcoming Immersion day. 
  
  Component upates on change to the 'currentIday' context state.
*/

import { useContext, useState, useEffect } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { Context } from "../Contexts/Context";

const useStyles = makeStyles((theme) => ({
  outerContainer: {
    marginLeft: "40px",
    marginBottom: "45px"
  },
  immersionDay: {
    fontFamily: "Roboto",
    fontSize: "28px",
    margin: "40px 0px 0px 0px",
  },
  iDayTitle: {
    color: "#0f8727",
    marginRight: "5px",
  },
  scheduledDate: {
    fontSize: "20px",
    fontFamily: "Roboto",
    margin: "10px 0px",
  },
  scheduledTitle: {
    color: "#256600",
    fontWeight: "400",
    marginRight: "10px",
  },
}));

const UpcomingIday = (props) => {
  const { setSelectedIDay } = props;
  const { currentIday, setCurrentIday } = useContext(Context);
  const [currentIDayDate, setCurrentIDayDate] = useState("");

  const classes = useStyles();

  //Set to next upcoming iday on component mount
  useEffect(() => {
    async function setUpcomingIday() {
      const upcomingDay = await calculateUpcomingIday();
      setCurrentIday(upcomingDay);
      setSelectedIDay(upcomingDay);
    }
    setUpcomingIday();
  }, []);

  //Format date on Iday change
  useEffect(() => {
    if (currentIday.Date) {
      const dateString = formatDateString(currentIday.Date);
      setCurrentIDayDate(dateString);
    } else {
      setCurrentIDayDate("");
    }
  }, [currentIday]);

  const calculateUpcomingIday = () => {
    return axios
      .get(`https://ojmc63dai6.execute-api.us-west-2.amazonaws.com/dev/dates`)
      .then((res) => {
        const iDays = res.data;
        const today = new Date().toISOString().substring(0, 10);

        //sort immersion days by date to find next upcoming iday
        iDays.sort(function (a, b) {
          if (a.Date.length === 0 && b.Date.length > 0) return 1;
          else if (b.Date.length === 0 && a.Date.length > 0) return -1;
          else if (a.Date < today && b.Date >= today) return 1;
          else if (b.Date < today && a.Date >= today) return -1;
          else return a.Date.localeCompare(b.Date);
        });

        //If no dates exist or no upcoming immersion day, set to empty
        if (iDays[0].Date.length === 0 || today > iDays[0].Date) return "";
        else return iDays[0];
      })
      .catch((err) => {
        console.error("Axios GET call to '/dates' endpoint failed -- " + err);
      });
  };

  const formatDateString = (date) => {
    //Change string from 'yyyy-mm-dd' format to 'mm/dd/yyyy' format
    let dateArray = date.split("-");
    dateArray = dateArray.map((number) => {
      return parseInt(number, 10);
    });
    return `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`;
  };

  return (
    <>
      <link
        href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap"
        rel="stylesheet"
      />
      <div className={classes.outerContainer}>
        <p className={classes.immersionDay}>
          <span className={classes.iDayTitle}>Upcoming Immersion Day:</span>
          {currentIday.Name ? currentIday.Name : "None"}
        </p>
        <p className={classes.scheduledDate}>
          <span
            className={classes.scheduledTitle}
            style={
              currentIDayDate
                ? { visibility: "visible" }
                : { visibility: "hidden" }
            }
          >
            Scheduled Date:
          </span>
          {currentIDayDate}
        </p>
        <p className={classes.scheduledDate}>
            <span className={classes.scheduledTitle}>
              <a href="https://ca-immersionday-sso.awsapps.com/start" style={{textDecoration: "none"}}>CA Immersion Day Sign In</a>
              <br />
              <a href="https://commerce-architects.atlassian.net/wiki/spaces/CL/pages/2330525726/Immersion+Day+Runbook" style={{textDecoration: "none"}}>Immersion Day Runbook</a>
            </span>
        </p>
      </div>
    </>
  );
};

export default UpcomingIday;
