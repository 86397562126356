import { makeStyles } from "@material-ui/core";
import { useContext, useState } from "react";
import { Context } from "../Contexts/Context";
import NavBar from "../Components/NavBar";
import ModalWrapper from "../Components/ModalWrapper";
import WipeAccount from "../Components/WipeAccount";
import ChangeImmersionDay from "../Components/ChangeImmersionDay";
import UpcomingIday from "../Components/UpcomingIday";
import UserTable from "../Components/UserTable";
import UploadCSV from "../Components/UploadCSV";

const HomePage = () => {
  const useStyles = makeStyles(() => ({}));

  // These users will eventually come from Database call (most likely inside useEffect)
  const { isModalOpen, setIsModalOpen } = useContext(Context);
  const [selectedIDay, setSelectedIDay] = useState({ Name: "", Date: "" });

  //Controls which page to open in modal
  const [pageToOpen, setPageToOpen] = useState("");

  //Modal State handlers
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenModal = (pageToOpen) => {
    setPageToOpen(pageToOpen);
    setIsModalOpen(true);
  };

  const renderSwitch = () => {
    //For determining which page from the Nav Bar to render inside modal
    let modalContent;
    switch (pageToOpen) {
      case "upload":
        modalContent = <UploadCSV handleClose={handleCloseModal} />;
        break;

      case "change-iday":
        modalContent = <ChangeImmersionDay handleClose={handleCloseModal} selectedIDay={selectedIDay} setSelectedIDay={setSelectedIDay} />;
        break;

      case "wipe-account":
        modalContent = <WipeAccount handleClose={handleCloseModal} />;
        break;

      default:
        modalContent = <>Page not recognized</>;
        break;
    }

    return modalContent;
  };

  const classes = useStyles();
  return (
    <>
      <NavBar
        handleOpenModal={handleOpenModal}
        handleCloseModal={handleCloseModal}
      />
      <UpcomingIday setSelectedIDay={setSelectedIDay} />
      <UserTable selectedIDay={selectedIDay} />
      <ModalWrapper handleClose={handleCloseModal} open={isModalOpen}>
        {renderSwitch()}
      </ModalWrapper>
    </>
  );
};

export default HomePage;
