import { useEffect, useState } from "react";
import { withAuthenticator } from "aws-amplify-react";
import { Auth } from "aws-amplify";
import axios from "axios";

import { VERIFICATION_ENDPOINT } from "./Config/Constants";

import HomePage from "./Pages/HomePage";

const MyTheme = {
  googleSignInButton: { backgroundColor: "#0f8e4f", borderColor: "#7a7a7a" },
  button: { backgroundColor: "#7a7a7a" },
};

function App() {
  const [logIn, setLogIn] = useState(false);

  useEffect(() => {
    //Gets user after federated login, verifies Google Token
    let verified = getUser();
    setLogIn(verified);

    return function cleanUp() {
      Auth.signOut();
    };
  }, []);

  const getUser = async () => {
    // Get current Authenticated User from Amplify
    let user = await Auth.currentAuthenticatedUser();

    // Check with google (through lambda) that token is a derived from Google
    let verifiedToken = await verifyToken(user.token);

    return { user: user, verifiedToken: verifiedToken };
  };

  const verifyToken = async (tokenId) => {
    //verify google token with Lambda function hit through API Gateway endpoint
    const body = { tokenId: tokenId };
    let verified = false;
    await axios
      .post(VERIFICATION_ENDPOINT, body)
      .then((data) => {
        console.log(data);
        verified = parseTokenVerification(data.data);
      })
      .catch((error) => {
        console.log(error);
        return false;
      });

    return verified;
  };

  const parseTokenVerification = (data) => {
    //determine outcome of Google Token Verification
    if (data.statusCode === 200) {
      console.log("Login Success");
      return true;
    } else {
      console.log("Login Failure: Status Code " + data.statusCode);
      console.log(data.body);
      return false;
    }
  };

  const Application = (
    <>
      <HomePage />
    </>
  );

  return <div>{logIn ? Application : <div>NOT LOGGED IN</div>}</div>;
}

export default withAuthenticator(App, true, [], null, MyTheme);
