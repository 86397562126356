/*
  User table component displays users from the dynamoDB 'accounts' table if the user's name is not empty. 
  (Meaning that the user is being used for an upcoming immersion day).
  
  Component allows user to email credentials to users selected from table and also allows user deletion from table. 
  
  Deletion of a user will do the following:
  - Account is moved back to the holdingOU
  - In the database table, the user record's email, name and OU are set back to empty.
  - In the database table, the user record's sentEmail is set to false.
  - Account removes its user assignment
  - Account removes the 'AttendeeReadOnly' group assignment.

*/
import { makeStyles, Button } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { useContext, useState, useEffect } from "react";
import { Context } from "../Contexts/Context";
import axios from "axios";

const useStyles = makeStyles(() => ({
  buttonContainer: {
    display: "flex",
    marginTop: "20px",
    justifyContent: "center",
  },
  credButton: {
    flex: 1,
    maxWidth: "600px",
  },
}));

const sendEmailsUrl = "https://ojmc63dai6.execute-api.us-west-2.amazonaws.com/dev/sendEmails";

const UserTable = (props) => {
  const { selectedIDay } = props;

  const classes = useStyles();

  const userAttr = { NAME: 0, EMAIL: 1, CREDENTIALS_SENT: 2, ACCOUNT_ALIAS: 3 };

  const { users, setUsers, accounts, setAccounts } = useContext(Context);

  // Controls users selected in table
  const [selectedUsers, setSelectedUsers] = useState([]);

  // Table props
  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        sort: true,
        filter: true
      }
    },
    {
      name: "email",
      label: "Email",
      options: {
        sort: true,
        filter: true
      }
    },
    {
      name: "credentials sent",
      label: "Credentials Sent",
      options: {
        sort: true,
        filter: true
      }
    },
    {
      name: "accountId",
      label: "AWS Account Id",
      options: {
        sort: true,
        filter: false
      }
    },
    {
      name: "accountAlias",
      label: "AWS Account Alias",
      options: {
        sort: true,
        filter: true
      },
    },
    {
      name: "username",
      label: "Username"
    },
    {
      name: "password",
      label: "Password"
    }
  ];

  const options = {
    onRowSelectionChange: (a, b, c) => {
      //store selected user indexes
      setSelectedUsers(c);
    },
    onRowsDelete: () => {
      return onDeleteUser();
    },
  };

  //Grab all accounts from dynamoDB and populate the user table on component mount
  useEffect(()=>{
    axios
    .get("https://ojmc63dai6.execute-api.us-west-2.amazonaws.com/dev/users")
    .then((res)=>{
      updateUsers(res.data);
      setAccounts(res.data);
    })
    .catch((err) => {
      console.error("Axios call to get accounts failed -- " + err);
    });
  },[])

  const updateUsers = (res) => {
    let userList = [];
    res.forEach((user) => {
      if(user.name){
        let credSent = user.emailSent === "true" ? "Yes" : "No";
        userList.push([user.name, user.email, credSent, user.accountId, user.accountAlias, user.username, user.password]);
      }
    })
    setUsers(userList);
  }

  const onSendCredentials = async (selectedIDay) => {
    if (selectedUsers.length === 0) {
      window.alert("No users were selected. Please select at least one user.");
      return;
    }

    let usersToSendCredentialsTo = [];
    for (const index of selectedUsers) {
      usersToSendCredentialsTo.push(users[index]);
    }

    //Confirm Sending
    let confirmation = window.confirm(
      `Do you want to send account credentials to users: ${usersToSendCredentialsTo.map(
        (user) => user[userAttr.NAME]
      )}?`
    );

    if (confirmation) {
      let updatedUsers = [];
      users.forEach((user, index) => {
        if (selectedUsers.includes(index))
          user[userAttr.CREDENTIALS_SENT] = "Yes";
        updatedUsers.push(user);
      });
      // pass immersion day too in as param thou (Can we get it as prop from parent component?)
      const { data } = await axios.post(sendEmailsUrl, {
        immersionDay: selectedIDay.Name,
        users: usersToSendCredentialsTo
      });
      setUsers(updatedUsers);
      const { emailResults } = data;
      let alert = "Email Results:\n";
      for (let i = 0; i < emailResults.length; i++) {
        const { email, name, success } = emailResults[i];
        alert += `Email for ${name} at ${email} arrived: ${success}\n`
      }
      window.alert(alert);
    }
  };

  const onDeleteUser = () => {
    if (selectedUsers.length > 0) {
      let updatedUsers = [];
      let deletingUsers = [];

      users.forEach((user, index) => {
        if (selectedUsers.includes(index)) {
          deletingUsers.push(user);
        } else {
          updatedUsers.push(user);
        }
      });

      //Confirm Deletion
      let confirmation = window.confirm(
        `Do you confirm account deletion of users: ${deletingUsers.map(
          (user) => user[0]
        )}`
      );
      if (confirmation) {
        // insert service that moves user back to holdling OU
        // insert service here that wipes user in database and removes account assignments from account.
        setUsers(updatedUsers);
        console.log("Deleted Users!");
      } else {
        // Stops deletion from table by returning false
        return false;
      }
    }
  };

  return (
    <>
      <MUIDataTable
        title={`${selectedIDay.Name} Immersion Day Users`}
        data={users}
        columns={columns}
        options={options}
      />
      <div className={classes.buttonContainer}>
        <Button
          className={classes.credButton}
          onClick={() => onSendCredentials(selectedIDay)}
          variant="contained"
        >
          Send Credentials
        </Button>
      </div>
    </>
  );
};

export default UserTable;
